/**
 * Styling for the portal
 */

.container {
	/* width: 100%; */
	/* border-style: solid;
	border-width: 2px; */
	/* height: 400px; */
}
.header {
	height: 400px;
	width: 100%;
	border-style: solid;
	border-width: 2px;
	border-color: red;
}

.form {
	text-align: center;
	border-style: solid;
	border-width: 2px;
	border-color: blue;
	margin-top: 40px;
}

.formgroup {
	text-align: center;
	align: center;
}

.userInput {
	max-width: 250px;
	margin:0 auto;
}

.dropdown {
	margin: 20px;
}
@media (max-width:480px) {
	.userInput {
		max-width: 100%;
	}
}

.patient-or-carer {
	width: 100px;
	margin: 2px;
}
.pin {
	margin:10px;
}

#notification {
	color: red;
	text-align: center;
}

/* ************************************************* */
/*  					SIDE BAR 					 */
/* ************************************************* */
.sidebar {
	display: flex;
	flex:1;
	flex-direction: column;
	height: 100%;
}

.sidebar-header {
	font-size:30px;
	color: white;
	text-align: center;
}
.sidebar-buttons {
	flex:1;
}

.sidebar-logout {
	text-align: center;
	margin: 10px;
}

/*********************************************** */
/* 					DASHBOARD 				 	 */
/*********************************************** */
.dashboard-header {
	font-size: 48px;
	text-align: center;
}

.dashboard-patient-header {
	font-size: 32px;
}
.dashboard-patient-list {

}
